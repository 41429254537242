<template>
    <div class="homeContainer">
        <div class="topBanner">
            <img src="../assets/topBanner.png" class="topImg"/>
        </div>
        <div class="line"></div>
        <div class="main">
            <img class="mainBack" src="../assets/homeBack.png">
            <div class="mainLeft">
                <div v-if="user_type==='student'" class="mainLeftContent">
                    <div v-for="item in nav" :key="item.name" class="navOutBox" @click="changeView(item)">
                        <div :class="{'navInBox':true,'selected':currentNav===item.router}">
                            <img :src="item.selected" class="iconImg" v-if="currentNav===item.router"/>
                            <img :src="item.unselected" class="iconImg" v-else/>
                            {{item.name}}
                        </div>
                    </div>
                </div>
                <div v-if="user_type==='educator'" class="mainLeftContent">
                    <div v-for="item in navContact" :key="item.name" class="navOutBox" @click="changeView(item)">
                        <div :class="{'navInBox':true,'selected':currentNav===item.router}">
                            <img :src="item.selected" class="iconImg" v-if="currentNav===item.router"/>
                            <img :src="item.unselected" class="iconImg" v-else/>
                            {{item.name}}
                        </div>
                    </div>
                </div>
                <div v-if="user_type==='employee'" class="mainLeftContent">
                    <div v-for="item in navEmployee" :key="item.name" class="navOutBox" @click="changeView(item)">
                        <div :class="{'navInBox':true,'selected':currentNav===item.router}">
                            <img :src="item.selected" class="iconImg" v-if="currentNav===item.router"/>
                            <img :src="item.unselected" class="iconImg" v-else/>
                            {{item.name}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="mainRight">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import { removeUserId,removeUserName,removeUserType,getUserType } from '../utils/store'
export default {
    data(){
        return{
            nav:[
                {name:'Pre-challenge',selected:require('../assets/nav/peixun1.png'),unselected:require('../assets/nav/peixun.png'),router:'/peixun'},
                {name:'Subjects',selected:require('../assets/nav/bisai1.png'),unselected:require('../assets/nav/bisai.png'),router:'/studentlist'},
                {name:'Results',selected:require('../assets/nav/chengji1.png'),unselected:require('../assets/nav/chengji.png'),router:'/score'},
                {name:'My Profile',selected:require('../assets/nav/geren1.png'),unselected:require('../assets/nav/geren.png'),router:'/personalinfo'},
                // {name:'消息',selected:require('../assets/nav/xiaoxi1.png'),unselected:require('../assets/nav/xiaoxi.png'),router:'/message'},
                {name:'Award',selected:require('../assets/nav/award1.png'),unselected:require('../assets/nav/award.png'),router:'/award'},//奖项
                {name:'Logout',selected:require('../assets/nav/tuichu1.png'),unselected:require('../assets/nav/tuichu.png')}
            ],
            navContact:[
                {name:'比赛项目',selected:require('../assets/nav/bisai1.png'),unselected:require('../assets/nav/bisai.png'),router:'/teacherlist'},
                {name:'个人信息',selected:require('../assets/nav/geren1.png'),unselected:require('../assets/nav/geren.png'),router:'/personalinfo'},
                // {name:'消息',selected:require('../assets/nav/xiaoxi1.png'),unselected:require('../assets/nav/xiaoxi.png'),router:'/message'},
                {name:'退出登录',selected:require('../assets/nav/tuichu1.png'),unselected:require('../assets/nav/tuichu.png')}
            ],
            navEmployee:[
                {name:'赛前培训',selected:require('../assets/nav/peixun1.png'),unselected:require('../assets/nav/peixun.png'),router:'/peixunadmin'},
                {name:'比赛项目',selected:require('../assets/nav/bisai1.png'),unselected:require('../assets/nav/bisai.png'),router:'/employeelist'},
                {name:'个人信息',selected:require('../assets/nav/geren1.png'),unselected:require('../assets/nav/geren.png'),router:'/personalinfo'},
                {name:'监考',selected:require('../assets/nav/bisai1.png'),unselected:require('../assets/nav/bisai.png'),router:'/examadminlist'},
                // {name:'消息',selected:require('../assets/nav/xiaoxi1.png'),unselected:require('../assets/nav/xiaoxi.png'),router:'/message'},
                {name:'退出登录',selected:require('../assets/nav/tuichu1.png'),unselected:require('../assets/nav/tuichu.png')}
            ],
            user_type:getUserType(),
            currentNav:'/peixun'
        }
    },
    watch:{
        $route(to,from){
            this.currentNav = this.$route.path
        }
    },
    mounted(){
        this.currentNav = this.$route.path
    },
    methods:{
        logout(){
            removeUserId()
            removeUserType()
            removeUserName()
            location.reload()
        },
        changeView(item){
            if(item.router){
                this.currentNav = item.router
                this.$router.push(item.router)
            }else{
                this.$confirm('Are you sure you want to exit?', 'Tips', {
                    confirmButtonText: 'confirm',
                    cancelButtonText: 'cancel',
                    type: 'warning'
                }).then(()=>{
                    this.logout()
                })
            }
            
        }
    }
}
</script>

<style lang="less">
.homeContainer{
    .topBanner{
        background: rgb(253,166,0);
        height: 18vh;
        text-align: center;
        .topImg{
            width: auto;
            height: 100%;
            display: block;
            margin: 0 auto;
        }
    }
    .line{
        width: 100%;
        height: 2vh;
        background: rgb(14,33,68);
    }
    .main{
        display: flex;
        height: 80vh;
        position: relative;
        .mainBack{
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
        .mainLeft{
            width: 30%;
            display: flex;
            justify-content: flex-end;
            .mainLeftContent{
                margin-right: 80px;
                .navOutBox{
                    margin-top: 3vh;
                    width: 200px;
                    height: 7.4vh;
                    background: #4782BF;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    cursor: pointer;
                    .navInBox{
                        height: 6.4vh;
                        width: 180px;
                        background: #1963AF;
                        line-height: 4vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 2vh;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #FFFFFF;
                        .iconImg{
                            height: 3vh;
                            margin-right: 6px;
                        }
                    }
                    .selected{
                        background: white;
                        color: #1963AF;
                    }
                }
            }
        }
        .mainRight{
            margin-top:2vh;
            width: 70%;
            height: 86%;
            overflow-y: scroll;
        }
    }
}
</style>